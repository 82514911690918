import { UserPageLink } from "~/components/links/UserPageLink";
import { User } from "~/generated/rtk-query/nowdo-live-api.generated";
import css from "./index.module.scss";
import { Image } from "~/components/Image";

export type ProfessionalCardProps = {
  professional: User;
  imageQuality?: number;
  imageSize: number;
};
export const ProfessionalCard: React.VFC<ProfessionalCardProps> = ({ professional, imageQuality, imageSize }) => (
  <UserPageLink user={professional}>
    <article className={css.card} key={professional.id}>
      {Boolean(professional.scheduledClassroomCount && professional.scheduledClassroomCount > 0) && (
        <div className={css.scheduledClassroomCount}>{professional.scheduledClassroomCount}</div>
      )}
      <div className={css.cartContent}>
        {/* メモ: 画像は正方形じゃないので、objectFit="cover"じゃないと画像が伸びます */}
        <Image
          className={css.avatar}
          src={professional.avatarUrl}
          width={imageSize}
          height={imageSize}
          alt=""
          quality={imageQuality}
        />

        {professional.professionalTitle ? (
          <p className={css.professionalTitle}>{professional.professionalTitle}</p>
        ) : (
          <p className={css.professionalTitle}>&nbsp;</p>
        )}
        <p className={css.name}>{professional.name}</p>
        {Boolean(professional.archiveCount && professional.archiveCount > 0) && (
          <p className={css.archiveCount}>{professional.archiveCount}件の動画</p>
        )}
      </div>
    </article>
  </UserPageLink>
);
