import { Category, Interest } from "~/generated/rtk-query/nowdo-live-api.generated";

export const ClassroomState = {
  scheduled: "scheduled",
  opened: "opened",
  closed: "closed",
} as const;

export type ClassroomState = (typeof ClassroomState)[keyof typeof ClassroomState];

export const ClassroomUserRole = {
  speaker: "speaker",
  audience: "audience",
  anonymous: "anonymous",
} as const;

export type ClassroomUserRole = (typeof ClassroomUserRole)[keyof typeof ClassroomUserRole];

export interface Classroom {
  id: string;
  name: string;
  userCount: number;
  imageURL: string;
  category: Category;
  // room owner
  user: ClassroomOwner;
  users: ClassroomUser[];
  reservedUsers: ClassroomUser[];
  description: string;
  startAt: number;
  endAt: number;
  state: ClassroomState;
  reservationUserCount: number;
  allUsers: boolean;
  audienceMode: boolean;
  pickup: boolean;
  reserved: boolean;
  interests: Interest[];
  privateMode: boolean;
}

export interface ClassroomOwner {
  id: string;
  name: string;
  avatar_url: string;
  nickname: string;
  introduction: string;
  is_professional: boolean;
  professional_title: string;
  is_premium: false;
  follower_count: number;
  following_count: number;
  is_followed: boolean;
  registered_at: number;
  interests: Interest[];
  joining_classroom: unknown;
  will_join_classroom: null;
  post_join_classroom: null;
}

export interface CommentUser {
  id: string;
  name: string;
  avatarUrl: string;
  role: ClassroomUserRole;
}

export interface ClassroomComment {
  id: string;
  text: string;
  createdAt: number;
  user: CommentUser;
  deleted?: boolean;
}

export interface ClassroomUser {
  id: string;
  fullName: string;
  avatarUrl: string;
  isProfessional: boolean;
  joinedAt: number;
  role: ClassroomUserRole;
}
