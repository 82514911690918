import React, { PropsWithChildren, ReactNode } from "react";
import classnames from "classnames";
import css from "./Section.module.scss";
import { Theme } from "~/types/theme";

export type SectionProps = React.PropsWithChildren<{ withInner?: boolean }>;
export const Section: React.FC<SectionProps> = ({ children, withInner }) => {
  if (withInner) {
    return (
      <section className={css.section}>
        <div className={css.inner}>{children}</div>
      </section>
    );
  }

  return <section className={css.section}>{children}</section>;
};

export type SectionTitleProps = React.PropsWithChildren<{
  noBorder?: boolean;
  theme?: Theme;
}>;
export const SectionTitle: React.FC<SectionTitleProps> = ({ children, noBorder, theme = "light" }) => (
  <h2
    className={classnames(css.title, {
      [css.border]: !noBorder,
      [css.colorWhite]: theme === "dark",
    })}
  >
    {children}
  </h2>
);

type SectionTitleWithButtonProps = PropsWithChildren<{
  button: ReactNode;
}>;
export const SectionTitleWithButton: React.FC<SectionTitleWithButtonProps> = ({ children, button }) => (
  <h2 className={`${css.title} ${css.space}`}>
    {children} {button}
  </h2>
);
