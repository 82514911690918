import React from "react";
import { SectionTitle } from "~/components/aud/base/Section";
import { Classrooms } from "~/components/aud/ui/Classrooms";
import css from "./ClassroomsWrapper.module.scss";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Classroom } from "~/generated/rtk-query/nowdo-live-api.generated";
import classnames from "classnames";

type ClassroomsWrapperTitleProps = {
  padding?: boolean;
  noBorder?: boolean;
};

type ClassroomsWrapperProps = ClassroomsWrapperTitleProps & {
  title: string;
  classrooms: Classroom[];
  wrap?: boolean;
};

export const ClassroomsWrapperTitle: React.FC<React.PropsWithChildren<ClassroomsWrapperTitleProps>> = ({
  children,
  padding = true,
  noBorder,
}) => (
  <div className={classnames({ [css.maxWidth]: padding })}>
    <SectionTitle noBorder={noBorder}>{children}</SectionTitle>
  </div>
);

export const ClassroomsWrapper: React.FC<ClassroomsWrapperProps> = ({
  title,
  classrooms,
  padding = true,
  wrap = false,
  noBorder,
}) => (
  <div className={css.classroomsWrapper}>
    <ClassroomsWrapperTitle noBorder={noBorder} padding={padding}>
      {title}
    </ClassroomsWrapperTitle>
    <SimpleBar>
      <div
        className={classnames(css.classrooms, {
          [css.wrap]: wrap,
        })}
      >
        <Classrooms classrooms={classrooms} />
      </div>
    </SimpleBar>
  </div>
);
