import React, { ReactNode, useEffect, useRef } from "react";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { SectionTitle } from "~/components/aud/base/Section";
import css from "./index.module.scss";
import { useWindowSize } from "react-use";

type CardsContainerProps = {
  title?: string;
  noMaxWidth?: boolean;
  noBorder?: boolean;
  wrap?: boolean;
  children: ReactNode;
  scrollableNodeProps?: React.ComponentProps<"div">;
  scrollToRightEnd?: () => void;
  rightEndMargin?: number;
  hasScroll?: (has: boolean) => void;
  noSimpleBar?: false;
};
export const CardsContainer: React.FC<CardsContainerProps> = ({
  title,
  noMaxWidth = false,
  wrap = false,
  noSimpleBar = false,
  noBorder,
  children,
  scrollableNodeProps = {},
  scrollToRightEnd,
  rightEndMargin = 600,
  hasScroll,
}) => {
  const windowSize = useWindowSize();
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref.current && (scrollToRightEnd || hasScroll)) {
      // スクロールする場所があるか判定
      const noScroll = ref.current.scrollLeft === 0 && ref.current.offsetWidth === ref.current.scrollWidth;
      hasScroll?.(!noScroll);
      if (noScroll) {
        return;
      }

      if (!scrollToRightEnd) {
        return;
      }
      const handler = () => {
        if (!ref.current) {
          return;
        }
        let margin = rightEndMargin;
        // 体験的にmarginは横にそこそこスクロールできる場合のみ入れます
        if (ref.current.offsetWidth + rightEndMargin >= ref.current.scrollWidth) {
          margin = 0;
        }
        if (ref.current.scrollLeft + ref.current.offsetWidth + margin >= ref.current.scrollWidth) {
          scrollToRightEnd();
        }
      };
      ref.current.addEventListener("scroll", handler);
      return () => ref.current?.removeEventListener("scroll", handler);
    }
  }, [scrollToRightEnd, hasScroll, windowSize.width]);

  return (
    <div className={css.container}>
      {title && (
        <div
          className={classnames({
            [css.maxWidth]: !noMaxWidth,
          })}
        >
          <SectionTitle noBorder={noBorder}>{title}</SectionTitle>
        </div>
      )}
      {noSimpleBar ? (
        <div className={classnames(css.items, { [css.wrap]: wrap })}>{children}</div>
      ) : (
        <SimpleBar scrollableNodeProps={{ ...scrollableNodeProps, ref }}>
          <div className={classnames(css.items, { [css.wrap]: wrap })}>{children}</div>
        </SimpleBar>
      )}
    </div>
  );
};
