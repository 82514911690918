import Head from "next/head";
import classnames from "classnames";
import React, { useEffect, useMemo } from "react";
import { useUser } from "~/context/userContext";
import { Analytics } from "~/components/GoogleAnalytics";
import { ClassroomsWrapper } from "~/components/aud/ui/ClassroomsWrapper";
import { NextPage } from "next";
import { PageTitle } from "~/components/aud/ui/PageTitle";
import { Section } from "~/components/aud/base/Section";
import css from "./index.module.scss";
import { ClassroomState } from "~/types/classroom";
import { getInitialPageProps } from "~/store";
import {
  useListClassroomsQuery,
  useListArchivesQuery,
  useListProfessionalsQuery,
  useListMeFollowingQuery,
} from "~/hooks/rtk-query-api";
import { loadHomePageData } from "~/components/links/HomePageLink";
import { ArchivesPageLink } from "~/components/links/ArchivesPageLink";
import { CardsContainer } from "~/components/aud/ui/CardsContainer";
import { MiniArchiveCards } from "~/components/aud/ui/MiniArchiveCards";
import { ProfessionalsPageLink } from "~/components/links/ProfessionalsPageLink";
import { ProfessionalCards } from "~/components/aud/ui/ProfessionalCards";
import { FollowingUsers } from "~/components/aud/ui/FollowingUsers";
import { ResponsiveCreateClassroomButton } from "~/components/aud/ui/ResponsiveCreateClassroomButton";

const HomePage: NextPage = () => {
  const { loadingUser, user } = useUser();
  const under12Student = !user?.canCommunicateAge && user?.isStudentAge;
  const { data: classrooms, isLoading: isLoadingClassrooms } = useListClassroomsQuery({});
  const archivesQuery = useListArchivesQuery({});
  const professionalsQuery = useListProfessionalsQuery({
    sort: "classroom",
  });
  const listMeFollowingQuery = useListMeFollowingQuery({}, { skip: !user, refetchOnMountOrArgChange: true });

  const { pickupArchives, latestArchives } = useMemo(() => {
    const archives = archivesQuery.data?.archives || [];

    return {
      pickupArchives: archives.filter((archive) => archive.pickup).slice(0, 10),
      latestArchives: archives.slice(0, 10),
    };
  }, [archivesQuery.data?.archives]);

  useEffect(() => {
    if (!loadingUser) {
      Analytics({ action: "view_top_page", label: "TopPage", user: user });
    }
  }, [loadingUser, user]);

  const liveClassrooms = useMemo(() => {
    if (classrooms) {
      return classrooms.classrooms.filter((classroom) => classroom.state === ClassroomState.opened);
    }
    return [];
  }, [classrooms]);
  const pickupClassrooms = useMemo(() => {
    if (classrooms) {
      return classrooms.classrooms.filter((classroom) => classroom.pickup);
    }
    return [];
  }, [classrooms]);
  const scheduledClassrooms = useMemo(() => {
    if (classrooms) {
      return classrooms.classrooms.filter((classroom) => classroom.state === ClassroomState.scheduled);
    }
    return [];
  }, [classrooms]);
  const followedClassrooms = useMemo(() => {
    if (classrooms) {
      return classrooms.classrooms.filter((classroom) => classroom.user.isFollowed);
    }
    return [];
  }, [classrooms]);

  return (
    <main>
      <Head>
        <title>NowDo / 好きなことで 生きていくための ソーシャルスクール</title>
        <meta name="description" content="好きなことで 生きていくための ソーシャルスクール" />
        <meta property="og:title" content="NowDo ナウドゥ" />
        <meta property="og:description" content="好きなことで 生きていくための ソーシャルスクール" />
        <meta property="og:url" content="https://nowdo.net/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://nowdo.net/images/ogp.png" />
      </Head>

      {!isLoadingClassrooms && classrooms && classrooms.classrooms.length > 0 && !under12Student && (
        <Section>
          <div className={classnames(css.maxWidth, css.pageTitle)}>
            <PageTitle
              title="Classroom"
              sub="クラスルーム"
              childrenWrapperProps={{
                className: css.classroomButtonSpWrapper,
              }}
            >
              {user && <ResponsiveCreateClassroomButton />}
            </PageTitle>
          </div>
          {listMeFollowingQuery.data && listMeFollowingQuery.data.following.length > 0 && (
            <CardsContainer noMaxWidth>
              <FollowingUsers users={listMeFollowingQuery.data.following} />
            </CardsContainer>
          )}
          <div>
            {liveClassrooms.length > 0 && (
              <ClassroomsWrapper title="開催中のクラスルーム" classrooms={liveClassrooms} />
            )}
            {pickupClassrooms.length > 0 && <ClassroomsWrapper title="ピックアップ" classrooms={pickupClassrooms} />}
            {scheduledClassrooms.length > 0 && (
              <ClassroomsWrapper title="開催予定のクラスルーム" classrooms={scheduledClassrooms} />
            )}
            {followedClassrooms.length > 0 && <ClassroomsWrapper title="フォロー中" classrooms={followedClassrooms} />}
          </div>
        </Section>
      )}
      {professionalsQuery.data && professionalsQuery.data.professionals.length > 0 && (
        <Section>
          <div className={css.maxWidth}>
            <PageTitle title="Professionals" sub="プロフェッショナル一覧">
              <ProfessionalsPageLink>
                <span className="_only__pc">すべてのプロフェッショナルを見る</span>
                <span className="_only__sp">もっと見る</span>
              </ProfessionalsPageLink>
            </PageTitle>
          </div>
          <div>
            <CardsContainer noMaxWidth>
              <ProfessionalCards professionals={professionalsQuery.data.professionals} imageSize={80} />
            </CardsContainer>
          </div>
        </Section>
      )}
      {(pickupArchives.length > 0 || latestArchives.length > 0) && (
        <Section>
          <div className={css.maxWidth}>
            <PageTitle title="Archives" sub="アーカイブ動画">
              <ArchivesPageLink>
                <span className="_only__pc">すべてのアーカイブを見る</span>
                <span className="_only__sp">もっと見る</span>
              </ArchivesPageLink>
            </PageTitle>
          </div>
          <div>
            {pickupArchives.length > 0 && (
              <CardsContainer title="ピックアップ">
                <MiniArchiveCards archives={pickupArchives} />
              </CardsContainer>
            )}
            {latestArchives.length > 0 && (
              <CardsContainer title="最新のアーカイブ">
                <MiniArchiveCards archives={latestArchives} />
              </CardsContainer>
            )}
          </div>
        </Section>
      )}
    </main>
  );
};

HomePage.getInitialProps = getInitialPageProps((store) => async () => {
  try {
    await loadHomePageData(store);
    // eslint-disable-next-line no-empty
  } catch (_) {}
});

export default HomePage;
