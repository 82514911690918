import React from "react";
import css from "./Classrooms.module.scss";
import { Image } from "~/components/Image";
import { format, fromUnixTime } from "date-fns";
import { ClassroomState } from "~/types/classroom";
import { ClassroomPageLink } from "~/components/links/ClassroomPageLink";
import { Classroom } from "~/generated/rtk-query/nowdo-live-api.generated";

export const Classrooms: React.FC<{ classrooms: Classroom[] }> = ({ classrooms }) => (
  <div className={css.classrooms}>
    {classrooms.map((classroom) => (
      <Card classroom={classroom} key={classroom.id} />
    ))}
  </div>
);

function getDataTest(classroom: Classroom) {
  let dataTest = "classroom";
  if (classroom.allUsers) {
    dataTest += "-all-users";
  }
  if (classroom.audienceMode) {
    dataTest += "-audience-mode";
  }
  return {
    "data-test": dataTest,
  };
}

const Card: React.FC<{ classroom: Classroom }> = ({ classroom }) => {
  return (
    <article>
      <ClassroomPageLink
        classroomId={classroom.id}
        aProps={{
          className: css.classroom,
          ...getDataTest(classroom),
        }}
      >
        <div className={css.classroomBox}>
          <Image className={css.classroomThumbnail} src={classroom.imageURL} width={300} height={230} alt="" />
          <ClassroomUserInfo classroom={classroom} />
          {classroom.reserved && (
            <div className={css.check}>
              <Image src="/images/icon_check.svg" width={16} height={16} alt="This is a reserved classroom" />
            </div>
          )}
        </div>
        <ClassroomInfo classroom={classroom} />
      </ClassroomPageLink>
    </article>
  );
};

const ClassroomUserInfo: React.FC<{ classroom: Classroom }> = ({ classroom }) => {
  return (
    <div className={css.userInfoBox}>
      <div className={css.userInfoText}>
        {classroom.user.isProfessional && <span className={css.userStatus}>PRO</span>}
        <p className={css.userName}>{classroom.user.name}</p>
        <p className={css.userLength}>
          {classroom.state === ClassroomState.opened
            ? `${classroom.users.length}人が参加中です`
            : `${classroom.reservationUserCount}人が待ってます`}
        </p>
      </div>
      <div
        className={css.userInfoThumbnail}
        style={{
          backgroundImage: `url(${classroom.user.avatarUrl}?w=200&h=200)`,
        }}
      />
    </div>
  );
};

const ClassroomInfo: React.FC<{ classroom: Classroom }> = ({ classroom }) => (
  <div>
    <p className={css.classroomName}>{classroom.name}</p>
    {classroom.description && (
      <div className={css.classroomDescriptionBox}>
        <p className={css.classroomDescription}>{classroom.description}</p>
      </div>
    )}
    {classroom.state === ClassroomState.opened && (
      <div className={css.live}>
        <Image src="/images/icon_live.svg" width={18} height={12} alt="classroom is session" />
        <p>LIVE</p>
      </div>
    )}
    {classroom.state === ClassroomState.scheduled && (
      <div className={css.schedule}>
        <p>
          {format(fromUnixTime(classroom.startAt), `MM/dd E HH:mm`).toUpperCase()}~
          {format(fromUnixTime(classroom.endAt), `HH:mm`)}
        </p>
      </div>
    )}
  </div>
);
