import Link, { LinkProps } from "next/link";
import { useStore } from "~/hooks/useStore";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { AppStore } from "~/store";
import { CustomPageLink } from "~/types/customPageLink";

type Result = {
  isUninitialized: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

export const loadArchivePageData = async (store: AppStore, archiveId: string): Promise<Result> => {
  const meQuery = nowdoApi.endpoints.getMe.select()(store.getState());
  const job: Promise<any>[] = [
    store.dispatch(nowdoApi.endpoints.listRecommendArchives.initiate({ id: archiveId })),
    store.dispatch(nowdoApi.endpoints.listSubscriptionPlans.initiate()),
  ];
  if (meQuery.data && !meQuery.data.isSubscribed) {
    job.push(store.dispatch(nowdoApi.endpoints.getMeSubscriptionTrial.initiate()));
  }
  const archiveQuery = await store.dispatch(nowdoApi.endpoints.getArchive.initiate({ id: archiveId }));
  if (archiveQuery.isSuccess) {
    job.push(
      store.dispatch(
        nowdoApi.endpoints.getUser.initiate({
          id: archiveQuery.data?.archive.owners[0].id,
        })
      )
    );
  }
  const results: Result[] = await Promise.all(job);
  for (const result of results) {
    if (result.isError) {
      return result;
    }
  }
  return archiveQuery;
};

export const ArchivePageLink: CustomPageLink<{
  archiveId: string;
}> = ({ aProps = {}, children, archiveId, ...linkProps }) => {
  const store = useStore();

  const props = {
    ...linkProps,
    ...aProps,
  } as Partial<LinkProps>;

  return (
    <Link
      {...props}
      href="/archives/[id]"
      as={`/archives/${archiveId}`}
      onMouseEnter={() => aProps.target !== "_blank" && loadArchivePageData(store, archiveId)}
    >
      {children}
    </Link>
  );
};
