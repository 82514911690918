import classnames from "classnames";
import { useIsOpenCreateClassroomModal } from "../providers/IsOpenCreateClassroomModalProvider";

export const ResponsiveCreateClassroomButton: React.FC = () => {
  const { setIsOpenCreateClassroom } = useIsOpenCreateClassroomModal();

  return (
    <>
      <a
        className={classnames(["_button", "primary", "_only__pc", "submedium"])}
        onClick={(e) => {
          e.preventDefault();
          setIsOpenCreateClassroom(true);
        }}
      >
        クラスルームを作成
      </a>
      <a
        className={classnames(["_button", "primary", "_only__sp", "submedium"])}
        onClick={(e) => {
          e.preventDefault();
          setIsOpenCreateClassroom(true);
        }}
      >
        作成
      </a>
    </>
  );
};
