import React from "react";
import { User } from "~/generated/rtk-query/nowdo-live-api.generated";
import css from "./index.module.scss";
import { ProfessionalCard } from "../ProfessionalCard";

type ProfessionalCardsProps = { professionals: User[]; imageSize: number };
export const ProfessionalCards: React.FC<ProfessionalCardsProps> = ({ professionals, imageSize }) => (
  <div className={css.cards}>
    {professionals.map((professional) => (
      <ProfessionalCard imageSize={imageSize} professional={professional} key={professional.id} />
    ))}
  </div>
);
