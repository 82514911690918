import { Image } from "~/components/Image";
import React from "react";
import { ArchivePageLink } from "~/components/links/ArchivePageLink";
import { Archive } from "~/generated/rtk-query/nowdo-live-api.generated";
import css from "./index.module.scss";

export const MiniArchiveCard: React.FC<{ archive: Archive }> = ({ archive }) => {
  return (
    <article>
      <ArchivePageLink
        archiveId={archive.id}
        aProps={{
          className: css.card,
        }}
      >
        <div className={css.card__inner}>
          <Image className={css.card__thumbnail} src={archive.imageUrl} width={240} height={160} alt="" />
        </div>
        <div>
          <p className={css.card__name}>{archive.title}</p>
          <div className={css.card__count}>
            <p>{archive.watchCount}人が視聴</p>
          </div>
        </div>
      </ArchivePageLink>
    </article>
  );
};
