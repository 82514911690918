import React from "react";
import classnames from "classnames";
import { Archive } from "~/generated/rtk-query/nowdo-live-api.generated";
import { MiniArchiveCard } from "~/components/aud/ui/MiniArchiveCard";
import css from "./index.module.scss";

export const MiniArchiveCards: React.FC<{
  archives: Archive[];
  wrap?: boolean;
}> = ({ archives, wrap }) => (
  <div
    className={classnames([
      css.cards,
      {
        [css.wrap]: wrap,
      },
    ])}
  >
    {archives.map((archive) => (
      <MiniArchiveCard archive={archive} key={archive.id} />
    ))}
  </div>
);
