import Link, { LinkProps } from "next/link";
import { useStore } from "~/hooks/useStore";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { AppStore } from "~/store";
import { CustomPageLink } from "~/types/customPageLink";
import css from "./Link.module.scss";
import classnames from "classnames";

export const loadProfessionalsPageData = async (store: AppStore) => {
  await store.dispatch(nowdoApi.endpoints.listProfessionals.initiate({ sort: "archive" }));
};

export const ProfessionalsPageLink: CustomPageLink = ({ aProps = {}, children, ...linkProps }) => {
  const store = useStore();

  const props = {
    ...linkProps,
    ...aProps,
  } as Partial<LinkProps>;

  return (
    <Link
      {...props}
      href="/professionals"
      className={classnames([aProps.className, css.aTag])}
      onMouseEnter={() => aProps.target !== "_blank" && loadProfessionalsPageData(store)}
    >
      {children}
    </Link>
  );
};
