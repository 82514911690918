import Link, { LinkProps } from "next/link";
import { useStore } from "~/hooks/useStore";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { AppStore } from "~/store";
import { CustomPageLink } from "~/types/customPageLink";

export const loadClassroomPageData = (store: AppStore, classroomId: string) => {
  return store.dispatch(nowdoApi.endpoints.getClassroom.initiate({ id: classroomId }));
};

export const ClassroomPageLink: CustomPageLink<{
  classroomId: string;
}> = ({ aProps = {}, children, classroomId, ...linkProps }) => {
  const store = useStore();

  const props = {
    ...linkProps,
    ...aProps,
  } as Partial<LinkProps>;

  return (
    <Link
      {...props}
      href="/classrooms/[id]"
      as={`/classrooms/${classroomId}`}
      onMouseEnter={() => aProps.target !== "_blank" && loadClassroomPageData(store, classroomId)}
    >
      {children}
    </Link>
  );
};
