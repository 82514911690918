import React from "react";
import { UserFollow } from "~/generated/rtk-query/nowdo-live-api.generated";
import css from "./index.module.scss";
import classnames from "classnames";
import { ClassroomPageLink } from "~/components/links/ClassroomPageLink";
import { UserPageLink } from "~/components/links/UserPageLink";
import { Image } from "~/components/Image";

const UserIcon: React.FC<{ user: UserFollow }> = ({ user }) => {
  const alt = user.classroomId ? `Go to ${user.name}'s classroom page` : `Go to ${user.name}'s profile page`;

  const same = (
    <Image
      src={user.avatarUrl}
      className={classnames(css.avatarImage, {
        [css.active]: user.classroomId,
      })}
      width={80}
      height={80}
      alt={alt}
    />
  );

  if (user.classroomId) {
    return <ClassroomPageLink classroomId={user.classroomId}>{same}</ClassroomPageLink>;
  }

  return <UserPageLink user={user}>{same}</UserPageLink>;
};

export const FollowingUsers: React.FC<{ users: UserFollow[] }> = ({ users }) => (
  <div className={css.cards}>
    {users.map((user) => (
      <UserIcon user={user} key={user.id} />
    ))}
  </div>
);
